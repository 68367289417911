import Vue from "vue";
import { extend, ValidationObserver, ValidationProvider } from "vee-validate";
import {
  max,
  min,
  numeric,
  required,
  min_value as minValue,
  regex,
  excluded,
  max_value as maxValue,
} from "vee-validate/dist/rules";
import moment from "moment";

extend("max", max);
extend("min", min);
extend("numeric", numeric);
extend("required", required);
extend("min_value", minValue);
extend("max_value", maxValue);
extend("regex", regex);
extend("excluded", excluded);
extend("email_or_params", (value) => {
  return (
    Array.isArray(value) &&
    value.every((v) => /^{{params.\d+}}$/.test(v) || /^.+@.+\..+$/.test(v))
  );
});
extend("single_email_or_params", (value) => {
  return /^.+@.+\..+$/.test(value) || /^{{params.\d+}}$/.test(value);
});
extend("json", (value) => {
  try {
    const {
      id,
      number,
      type,
      question: { label, type: questionType, visible, required } = {},
    } = JSON.parse(value);
    return (
      id &&
      typeof number === "string" &&
      type &&
      label &&
      questionType &&
      typeof required === "object" &&
      required !== null &&
      typeof visible === "object" &&
      visible !== null
    );
  } catch {
    return false;
  }
});
extend("form_json", (value) => {
  try {
    const { sections, formDescription } = JSON.parse(value);
    if (!Array.isArray(sections)) {
      return "The sections property must be an array.";
    }

    if (!formDescription) {
      return "The formDescription property must be an object.";
    }

    return (
      Array.isArray(sections) &&
      typeof formDescription === "object" &&
      formDescription !== null
    );
  } catch (error) {
    return false;
  }
});
extend("ends_with_regex", (value) => {
  const regexF = "/FeatureServer/[0-9]+";
  const suffixF = "/FeatureServer/<id>";
  const regexS = "/MapServer";
  const suffixS = "/MapServer";

  return new RegExp(`.*${regexF}$`).test(value) ||
    new RegExp(`.*${regexS}$`).test(value)
    ? true
    : `URL provided does not end in "${suffixF}" or "${suffixS}"`;
});
extend("passwordsMatch", {
  params: ["target"],
  validate(value, { target }) {
    return target === value;
  },
  message: "Password do not match.",
});
extend("hasAtLeastOneUpperChar", {
  validate(value) {
    return /[A-Z]+/.test(value);
  },
  message: "One upper case letter.",
});
extend("hasAtLeastOneNumber", {
  validate(value) {
    return /\d+/.test(value);
  },
  message: "One number",
});
extend("hasAtLeastOneLowerChar", {
  validate(value) {
    return /[a-z]+/.test(value);
  },
  message: "One lower case letter",
});
extend("passwordMin", {
  ...min,
  message: (_, { length }) => {
    length;
    return `Eight characters minimum`;
  },
});
extend("emailNotExist", {
  validate(value, contacts) {
    return !contacts.find((c) => c.email === value);
  },
  message: "Email already exists",
});
extend("emailNotExistEdit", {
  validate(value, [contact, contacts]) {
    return !contacts.find(
      (c) => c.email === value && contact.site_contact_id !== c.site_contact_id
    );
  },
  message: "Email already exists",
});
extend("daysSettingsRequired", {
  validate(daysSettings) {
    if (!Array.isArray(daysSettings) || daysSettings?.length === 0) {
      return false;
    }
    return daysSettings.filter((s) => s.value).length > 0;
  },
  message: "Select at least 1",
});
extend("checkboxRequired", {
  validate(checkboxChoices) {
    if (!Array.isArray(checkboxChoices) || checkboxChoices?.length === 0) {
      return false;
    }
    return checkboxChoices.filter((c) => c.value).length > 0;
  },
  message: "Select at least 1",
});
extend("oneActionRequired", {
  validate(workflowSettingBeingEdited) {
    if (!workflowSettingBeingEdited) {
      return false;
    }
    const {
      assign_ticket: assignTicket,
      send_email: sendEmail,
      send_sms: sendSms,
      close_ticket: closeTicket,
      set_service_request_priority: setServiceRequestPriority,
    } = workflowSettingBeingEdited?.actions ?? {};
    return [
      assignTicket?.enabled,
      sendEmail?.enabled,
      sendSms?.enabled,
      closeTicket?.enabled,
      setServiceRequestPriority?.enabled,
    ].some(Boolean);
  },
  message: "Select at least 1",
});
extend("dateSameOrAfter", {
  params: ["target"],
  validate(value, { target }) {
    return moment(target).isSameOrBefore(moment(value));
  },
  message: "From date must be before to date.",
});
extend("email", (value) => {
  const EMAIL_REGEX =
    /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z]{2,63}$/;
  if (!value || EMAIL_REGEX.test(value)) {
    return true;
  }
  return `${value} is not a valid email address.`;
});

Vue.component("validation-provider", ValidationProvider);
Vue.component("validation-observer", ValidationObserver);
