import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);
const APIURL = process.env.VUE_APP_API_URL;

export default new Vuex.Store({
  state: {
    showMenu: true,
    newFormData: {},
    errorOccurred: false,
    badRequestError: undefined,
    isBeaconOpen: false,
    utiliSyncFieldsUpdated: false,
    newReportData: {},
    emailSuppressions: [],
  },
  mutations: {
    setMenuState(state, payload) {
      state.showMenu = payload;
    },
    setErrorOccurred(state, payload) {
      state.errorOccurred = payload;
    },
    setBadRequestError(state, payload) {
      state.badRequestError = payload;
    },
    setNewFormData(state, payload) {
      state.newFormData = payload;
    },
    setBeaconOpen(state, payload) {
      state.isBeaconOpen = payload;
    },
    setUtiliSyncFieldsUpdated(state, payload) {
      state.utiliSyncFieldsUpdated = payload;
    },
    setNewReportData(state, payload) {
      state.newReportData = payload;
    },
    setEmailSuppressions(state, payload) {
      state.emailSuppressions = payload;
    },
  },
  actions: {
    async getEmailSuppressions({ commit }) {
      const {
        data: { results },
      } = await axios.get(`${APIURL}/email_suppressions`);
      commit("setEmailSuppressions", [...results]);
    },
  },
  modules: {},
  getters: {
    isMenuOpen(state) {
      return state.showMenu;
    },
    newFormData(state) {
      return state.newFormData;
    },
    errorOccurred(state) {
      return state.errorOccurred;
    },
    badRequestError(state) {
      return state.badRequestError;
    },
    isBeaconOpen(state) {
      return state.isBeaconOpen;
    },
    utiliSyncFieldsUpdated(state) {
      return state.utiliSyncFieldsUpdated;
    },
    newReportData(state) {
      return state.newReportData;
    },
    emailSuppressions(state) {
      return state.emailSuppressions;
    },
  },
});
