<template>
  <v-dialog :value="showErrorDialog" max-width="500px" persistent>
    <v-card>
      <v-card-text class="py-3">
        An error just occurred. Error: 500. Please try reloading the app. If
        this continues, please reach out to support and let them know the date
        and time the error occurred, so they can look it up in the logs.
      </v-card-text>

      <v-card-actions class="d-flex justify-end px-5 py-3">
        <v-btn text color="primary" @click="setErrorOccurred(false)">
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  name: "ErrorDialog",
  props: {
    showErrorDialog: Boolean,
  },
  methods: {
    ...mapMutations(["setErrorOccurred"]),
  },
};
</script>
