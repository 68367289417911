var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-list",
        {
          class: { "overflow-y-auto": _vm.$vuetify.breakpoint.xsOnly },
          attrs: { dense: "", height: "37vh" },
        },
        [
          _c(
            "v-list-item",
            { staticClass: "px-0" },
            [
              _c(
                "v-menu",
                {
                  attrs: { "offset-y": "", "min-width": "244px" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function ({ on, attrs }) {
                        return [
                          _c(
                            "v-list-item",
                            _vm._g(
                              _vm._b(
                                { attrs: { color: "primary" } },
                                "v-list-item",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [
                              _c("v-list-item-content", [
                                _c(
                                  "section",
                                  {
                                    staticClass:
                                      "d-flex justify-space-between align-center",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "mx-0 px-0",
                                        class: {
                                          "flex-grow-0":
                                            _vm.$vuetify.breakpoint.smAndUp,
                                          "flex-grow-1":
                                            _vm.$vuetify.breakpoint.xsOnly,
                                          truncate:
                                            _vm.$vuetify.breakpoint.smAndUp,
                                        },
                                        staticStyle: { color: "#286054" },
                                        style: {
                                          width: _vm.$vuetify.breakpoint.smAndUp
                                            ? "150px"
                                            : undefined,
                                        },
                                      },
                                      [
                                        _vm.currentUser
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "caption menu-text font-weight-medium",
                                                class: {
                                                  truncate:
                                                    _vm.$vuetify.breakpoint
                                                      .smAndUp,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.currentUser
                                                        .organization &&
                                                        _vm.currentUser
                                                          .organization.name
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm.currentUser
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "font-weight-medium",
                                                class: {
                                                  truncate:
                                                    _vm.$vuetify.breakpoint
                                                      .smAndUp,
                                                },
                                                staticStyle: {
                                                  "font-size": "16px",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.currentUser.f_name
                                                    ) +
                                                    " " +
                                                    _vm._s(
                                                      _vm.currentUser.l_name
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    ),
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "px-0 mx-0",
                                        attrs: { icon: "", color: "#673AB7" },
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          { attrs: { color: "#286054" } },
                                          [_vm._v(_vm._s(_vm.mdiChevronDown))]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c(
                    "v-list",
                    { staticClass: "py-0 px-0" },
                    [
                      _vm.cityworksAuthInvalid
                        ? _c(
                            "v-list-item",
                            {
                              staticClass: "py-0 px-0",
                              on: {
                                click: function ($event) {
                                  _vm.showUserCityworksSignInDialog = true
                                },
                              },
                            },
                            [
                              _c(
                                "v-btn",
                                { attrs: { text: "", color: "#286054" } },
                                [
                                  _c("v-icon", { staticClass: "mr-2" }, [
                                    _vm._v(_vm._s(_vm.mdiLoginVariant)),
                                  ]),
                                  _vm._v(" Sign In - Cityworks "),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _c(
                            "v-list-item",
                            {
                              staticClass: "py-0 px-0",
                              on: { click: _vm.signOutOfCityworks },
                            },
                            [
                              _c(
                                "v-btn",
                                { attrs: { text: "", color: "#286054" } },
                                [
                                  _c("v-icon", { staticClass: "mr-2" }, [
                                    _vm._v(_vm._s(_vm.mdiLogoutVariant)),
                                  ]),
                                  _vm._v(" Sign Out - Cityworks "),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                      _c("v-divider"),
                      _c(
                        "v-list-item",
                        {
                          staticClass: "py-0 px-0",
                          on: { click: _vm.signOut },
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "min-button",
                              attrs: {
                                id: "sign-out-button",
                                text: "",
                                color: "#286054",
                              },
                            },
                            [
                              _c("v-icon", { staticClass: "mr-2" }, [
                                _vm._v(_vm._s(_vm.mdiLogoutVariant)),
                              ]),
                              _vm._v(" Sign Out "),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-list-item",
            {
              staticClass: "py-0 my-0",
              attrs: { to: "/organization", color: "primary" },
            },
            [
              _c(
                "v-list-item-icon",
                [
                  _c("v-icon", { attrs: { color: "primary" } }, [
                    _vm._v(_vm._s(_vm.mdiDomain)),
                  ]),
                ],
                1
              ),
              _c("v-list-item-content", { staticClass: "nav-list-items" }, [
                _vm._v(" Organization "),
              ]),
            ],
            1
          ),
          _c(
            "v-list-item",
            {
              staticClass: "py-0 my-0",
              attrs: { to: "/contactBook", color: "primary" },
            },
            [
              _c(
                "v-list-item-icon",
                [
                  _c("v-icon", { attrs: { color: "primary" } }, [
                    _vm._v(_vm._s(_vm.mdiContacts)),
                  ]),
                ],
                1
              ),
              _c("v-list-item-content", { staticClass: "nav-list-items" }, [
                _vm._v(" Contact Book "),
              ]),
            ],
            1
          ),
          _c(
            "v-list-item",
            {
              staticClass: "py-0 my-0",
              attrs: { to: "/811Codes", id: "accounts811", color: "primary" },
            },
            [
              _c(
                "v-list-item-icon",
                [
                  _c("v-icon", { attrs: { color: "primary" } }, [
                    _vm._v(_vm._s(_vm.mdiOfficeBuildingMarker)),
                  ]),
                ],
                1
              ),
              _c("v-list-item-content", { staticClass: "nav-list-items" }, [
                _vm._v(" 811 Codes "),
              ]),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-list-item",
            {
              staticClass: "py-0 my-0 cursor-pointer",
              attrs: { color: "primary" },
              on: {
                click: function ($event) {
                  _vm.showDataUtilitiesMenu = !_vm.showDataUtilitiesMenu
                },
              },
            },
            [
              _c(
                "v-list-item-icon",
                [
                  _c("v-icon", { attrs: { color: "primary" } }, [
                    _vm._v(_vm._s(_vm.mdiCogPlay)),
                  ]),
                ],
                1
              ),
              _c("v-list-item-content", { staticClass: "nav-list-items" }, [
                _vm._v(" Data Utilities "),
              ]),
              _c(
                "v-list-item-icon",
                [
                  _c("v-icon", { attrs: { color: "primary" } }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.showDataUtilitiesMenu
                            ? _vm.mdiChevronUp
                            : _vm.mdiChevronDown
                        ) +
                        " "
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm.showDataUtilitiesMenu
            ? _c(
                "v-list-item",
                { attrs: { to: "/updateObjectIds" } },
                [
                  _c(
                    "v-list-item-content",
                    { staticStyle: { "padding-left": "27px" } },
                    [
                      _c(
                        "section",
                        { staticClass: "nav-list-items cursor-pointer" },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex align-center gap" },
                            [
                              _c("v-icon", { attrs: { color: "primary" } }, [
                                _vm._v(" " + _vm._s(_vm.mdiSyncAlert) + " "),
                              ]),
                              _vm._v(" Update ObjectIDs "),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.showDataUtilitiesMenu
            ? _c(
                "v-list-item",
                { attrs: { to: "/updateGlobalIds" } },
                [
                  _c(
                    "v-list-item-content",
                    { staticStyle: { "padding-left": "27px" } },
                    [
                      _c(
                        "section",
                        { staticClass: "nav-list-items cursor-pointer" },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex align-center gap" },
                            [
                              _c("v-icon", { attrs: { color: "primary" } }, [
                                _vm._v(" " + _vm._s(_vm.mdiSyncAlert) + " "),
                              ]),
                              _vm._v(" Update GlobalIDs "),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.showDataUtilitiesMenu
            ? _c(
                "v-list-item",
                { attrs: { to: "/featureService" } },
                [
                  _c(
                    "v-list-item-content",
                    { staticStyle: { "padding-left": "27px" } },
                    [
                      _c(
                        "section",
                        { staticClass: "nav-list-items cursor-pointer" },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex align-center gap" },
                            [
                              _c("v-icon", { attrs: { color: "primary" } }, [
                                _vm._v(" " + _vm._s(_vm.mdiLayersPlus) + " "),
                              ]),
                              _vm._v(" Feature Service "),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-list-item",
            {
              staticClass: "py-0 my-0",
              attrs: { to: "/basemaps", color: "primary" },
            },
            [
              _c(
                "v-list-item-icon",
                [
                  _c(
                    "v-icon",
                    {
                      staticStyle: {
                        "-webkit-transform": "scaleX(-1)",
                        transform: "scaleX(-1)",
                      },
                      attrs: { color: "primary" },
                    },
                    [_vm._v(" " + _vm._s(_vm.mdiMapLegend) + " ")]
                  ),
                ],
                1
              ),
              _c("v-list-item-content", { staticClass: "nav-list-items" }, [
                _vm._v(" Basemaps "),
              ]),
            ],
            1
          ),
          _c(
            "v-list-item",
            {
              staticClass: "py-0 my-0",
              attrs: { to: "/maps", color: "primary" },
            },
            [
              _c(
                "v-list-item-icon",
                [
                  _c(
                    "v-icon",
                    {
                      staticStyle: {
                        "-webkit-transform": "scaleX(-1)",
                        transform: "scaleX(-1)",
                      },
                      attrs: { color: "primary" },
                    },
                    [_vm._v(_vm._s(_vm.mdiMap))]
                  ),
                ],
                1
              ),
              _c("v-list-item-content", { staticClass: "nav-list-items" }, [
                _vm._v(" Maps "),
              ]),
            ],
            1
          ),
          _c(
            "v-list-item",
            {
              staticClass: "py-0 my-0",
              attrs: { to: "/layers", color: "primary" },
            },
            [
              _c(
                "v-list-item-icon",
                [
                  _c("v-icon", { attrs: { color: "primary" } }, [
                    _vm._v(_vm._s(_vm.mdiLayers)),
                  ]),
                ],
                1
              ),
              _c("v-list-item-content", { staticClass: "nav-list-items" }, [
                _vm._v(" Layers "),
              ]),
            ],
            1
          ),
          _c(
            "v-list-item",
            {
              staticClass: "py-0 my-0",
              attrs: { to: "/forms", color: "primary" },
            },
            [
              _c(
                "v-list-item-icon",
                [
                  _c("v-icon", { attrs: { color: "primary" } }, [
                    _vm._v(_vm._s(_vm.mdiClipboardCheck)),
                  ]),
                ],
                1
              ),
              _c("v-list-item-content", { staticClass: "nav-list-items" }, [
                _vm._v(" Forms "),
              ]),
            ],
            1
          ),
          _c(
            "v-list-item",
            {
              staticClass: "py-0 my-0",
              attrs: { to: "/reports", color: "primary" },
            },
            [
              _c(
                "v-list-item-icon",
                [
                  _c("v-icon", { attrs: { color: "primary" } }, [
                    _vm._v(_vm._s(_vm.mdiClipboardCheck)),
                  ]),
                ],
                1
              ),
              _c("v-list-item-content", { staticClass: "nav-list-items" }, [
                _vm._v(" Reports "),
              ]),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-list-item",
            {
              staticClass: "py-0 my-0",
              on: {
                click: function ($event) {
                  return _vm.$emit("open-capture-upload-dialog")
                },
              },
            },
            [
              _c(
                "v-list-item-icon",
                [
                  _c("v-icon", { attrs: { color: "primary" } }, [
                    _vm._v(_vm._s(_vm.mdiCameraIris)),
                  ]),
                ],
                1
              ),
              _c("v-list-item-content", { staticClass: "nav-list-items" }, [
                _vm._v(" Capture Screen "),
              ]),
            ],
            1
          ),
          _c(
            "v-list-item",
            {
              staticClass: "py-0 my-0",
              attrs: {
                href: "https://utilisync.helpscoutdocs.com/collection/181-admin",
                target: "_blank",
                color: "primary",
              },
            },
            [
              _c(
                "v-list-item-icon",
                [
                  _c("v-icon", { attrs: { color: "primary" } }, [
                    _vm._v(_vm._s(_vm.mdiBookOpen)),
                  ]),
                ],
                1
              ),
              _c("v-list-item-content", { staticClass: "nav-list-items" }, [
                _vm._v(" Knowledge Base "),
              ]),
            ],
            1
          ),
          _c(
            "v-list-item",
            {
              staticClass: "py-0 my-0",
              attrs: { color: "primary" },
              on: { click: _vm.showChat },
            },
            [
              _c(
                "v-list-item-icon",
                [
                  _c("v-icon", { attrs: { color: "primary" } }, [
                    _vm._v(_vm._s(_vm.mdiHelpCircle)),
                  ]),
                ],
                1
              ),
              _c("v-list-item-content", { staticClass: "nav-list-items" }, [
                _vm._v(" Support "),
              ]),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-list-item",
            {
              staticClass: "py-0 my-0",
              attrs: { to: "/versionLog", color: "primary" },
            },
            [
              _c(
                "v-list-item-content",
                {
                  staticClass: "nav-list-items",
                  staticStyle: { "font-size": "14px" },
                },
                [_vm._v(" Version " + _vm._s(_vm.versionNumber) + " ")]
              ),
            ],
            1
          ),
          _c("v-divider"),
        ],
        1
      ),
      _vm.showUserCityworksSignInDialog
        ? _c("UserCityworksSignInDialog", {
            attrs: {
              cityworksIntegrationInfo: _vm.cityworksIntegrationInfo,
              showUserCityworksSignInDialog: _vm.showUserCityworksSignInDialog,
            },
            on: {
              "cityworks-sign-in-dialog-close": function ($event) {
                _vm.showUserCityworksSignInDialog = false
              },
              "cityworks-sign-in-dialog-succeed": function ($event) {
                _vm.showUserCityworksSignInDialog = false
                _vm.validateCityworksToken()
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }