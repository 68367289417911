<template>
  <v-dialog :value="showBadRequestErrorDialog" max-width="500px" persistent>
    <v-card>
      <v-card-text class="py-3">
        An error occurred:

        <ul class="mx-0">
          <li v-for="e of badRequestError" :key="e">{{ e }}</li>
        </ul>
      </v-card-text>

      <v-card-actions class="d-flex justify-end px-5 py-3">
        <v-btn text color="primary" @click="setBadRequestError(undefined)">
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";

export default {
  name: "BadRequestErrorDialog",
  props: {
    showBadRequestErrorDialog: Boolean,
  },
  computed: {
    ...mapGetters(["badRequestError"]),
  },
  methods: {
    ...mapMutations(["setBadRequestError"]),
  },
};
</script>
