<template>
  <div>
    <v-list
      dense
      height="37vh"
      :class="{ 'overflow-y-auto': $vuetify.breakpoint.xsOnly }"
    >
      <v-list-item class="px-0">
        <v-menu offset-y min-width="244px">
          <template v-slot:activator="{ on, attrs }">
            <v-list-item v-bind="attrs" v-on="on" color="primary">
              <v-list-item-content>
                <section class="d-flex justify-space-between align-center">
                  <div
                    style="color: #286054"
                    class="mx-0 px-0"
                    :style="{
                      width: $vuetify.breakpoint.smAndUp ? '150px' : undefined,
                    }"
                    :class="{
                      'flex-grow-0': $vuetify.breakpoint.smAndUp,
                      'flex-grow-1': $vuetify.breakpoint.xsOnly,
                      truncate: $vuetify.breakpoint.smAndUp,
                    }"
                  >
                    <div
                      class="caption menu-text font-weight-medium"
                      :class="{ truncate: $vuetify.breakpoint.smAndUp }"
                      v-if="currentUser"
                    >
                      {{
                        currentUser.organization &&
                        currentUser.organization.name
                      }}
                    </div>
                    <div
                      v-if="currentUser"
                      class="font-weight-medium"
                      :class="{ truncate: $vuetify.breakpoint.smAndUp }"
                      style="font-size: 16px"
                    >
                      {{ currentUser.f_name }}
                      {{ currentUser.l_name }}
                    </div>
                  </div>

                  <v-btn icon color="#673AB7" class="px-0 mx-0">
                    <v-icon color="#286054">{{ mdiChevronDown }}</v-icon>
                  </v-btn>
                </section>
              </v-list-item-content>
            </v-list-item>
          </template>
          <v-list class="py-0 px-0">
            <v-list-item
              v-if="cityworksAuthInvalid"
              class="py-0 px-0"
              @click="showUserCityworksSignInDialog = true"
            >
              <v-btn text color="#286054">
                <v-icon class="mr-2">{{ mdiLoginVariant }}</v-icon>
                Sign In - Cityworks
              </v-btn>
            </v-list-item>
            <v-list-item v-else class="py-0 px-0" @click="signOutOfCityworks">
              <v-btn text color="#286054">
                <v-icon class="mr-2">{{ mdiLogoutVariant }}</v-icon>
                Sign Out - Cityworks
              </v-btn>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item @click="signOut" class="py-0 px-0">
              <v-btn
                id="sign-out-button"
                text
                class="min-button"
                color="#286054"
              >
                <v-icon class="mr-2">{{ mdiLogoutVariant }}</v-icon>
                Sign Out
              </v-btn>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-list-item>
      <v-divider></v-divider>

      <v-list-item to="/organization" class="py-0 my-0" color="primary">
        <v-list-item-icon>
          <v-icon color="primary">{{ mdiDomain }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content class="nav-list-items">
          Organization
        </v-list-item-content>
      </v-list-item>
      <v-list-item to="/contactBook" class="py-0 my-0" color="primary">
        <v-list-item-icon>
          <v-icon color="primary">{{ mdiContacts }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content class="nav-list-items">
          Contact Book
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        to="/811Codes"
        id="accounts811"
        class="py-0 my-0"
        color="primary"
      >
        <v-list-item-icon>
          <v-icon color="primary">{{ mdiOfficeBuildingMarker }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content class="nav-list-items">
          811 Codes
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list-item
        class="py-0 my-0 cursor-pointer"
        color="primary"
        @click="showDataUtilitiesMenu = !showDataUtilitiesMenu"
      >
        <v-list-item-icon>
          <v-icon color="primary">{{ mdiCogPlay }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content class="nav-list-items">
          Data Utilities
        </v-list-item-content>
        <v-list-item-icon>
          <v-icon color="primary">
            {{ showDataUtilitiesMenu ? mdiChevronUp : mdiChevronDown }}
          </v-icon>
        </v-list-item-icon>
      </v-list-item>

      <v-list-item v-if="showDataUtilitiesMenu" to="/updateObjectIds">
        <v-list-item-content style="padding-left: 27px">
          <section class="nav-list-items cursor-pointer">
            <div class="d-flex align-center gap">
              <v-icon color="primary">
                {{ mdiSyncAlert }}
              </v-icon>
              Update ObjectIDs
            </div>
          </section>
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-if="showDataUtilitiesMenu" to="/updateGlobalIds">
        <v-list-item-content style="padding-left: 27px">
          <section class="nav-list-items cursor-pointer">
            <div class="d-flex align-center gap">
              <v-icon color="primary">
                {{ mdiSyncAlert }}
              </v-icon>
              Update GlobalIDs
            </div>
          </section>
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-if="showDataUtilitiesMenu" to="/featureService">
        <v-list-item-content style="padding-left: 27px">
          <section class="nav-list-items cursor-pointer">
            <div class="d-flex align-center gap">
              <v-icon color="primary">
                {{ mdiLayersPlus }}
              </v-icon>
              Feature Service
            </div>
          </section>
        </v-list-item-content>
      </v-list-item>

      <v-list-item to="/basemaps" class="py-0 my-0" color="primary">
        <v-list-item-icon>
          <v-icon
            color="primary"
            style="-webkit-transform: scaleX(-1); transform: scaleX(-1)"
          >
            {{ mdiMapLegend }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content class="nav-list-items">
          Basemaps
        </v-list-item-content>
      </v-list-item>
      <v-list-item to="/maps" class="py-0 my-0" color="primary">
        <v-list-item-icon>
          <v-icon
            color="primary"
            style="-webkit-transform: scaleX(-1); transform: scaleX(-1)"
            >{{ mdiMap }}</v-icon
          >
        </v-list-item-icon>
        <v-list-item-content class="nav-list-items"> Maps </v-list-item-content>
      </v-list-item>
      <v-list-item to="/layers" class="py-0 my-0" color="primary">
        <v-list-item-icon>
          <v-icon color="primary">{{ mdiLayers }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content class="nav-list-items">
          Layers
        </v-list-item-content>
      </v-list-item>
      <v-list-item to="/forms" class="py-0 my-0" color="primary">
        <v-list-item-icon>
          <v-icon color="primary">{{ mdiClipboardCheck }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content class="nav-list-items">
          Forms
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item
        @click="$emit('open-capture-upload-dialog')"
        class="py-0 my-0"
      >
        <v-list-item-icon>
          <v-icon color="primary">{{ mdiCameraIris }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content class="nav-list-items">
          Capture Screen
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        href="https://utilisync.helpscoutdocs.com/collection/181-admin"
        target="_blank"
        class="py-0 my-0"
        color="primary"
      >
        <v-list-item-icon>
          <v-icon color="primary">{{ mdiBookOpen }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content class="nav-list-items">
          Knowledge Base
        </v-list-item-content>
      </v-list-item>
      <v-list-item @click="showChat" class="py-0 my-0" color="primary">
        <v-list-item-icon>
          <v-icon color="primary">{{ mdiHelpCircle }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content class="nav-list-items">
          Support
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>

      <v-list-item class="py-0 my-0" to="/versionLog" color="primary">
        <v-list-item-content class="nav-list-items" style="font-size: 14px">
          Version {{ versionNumber }}
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
    </v-list>

    <UserCityworksSignInDialog
      v-if="showUserCityworksSignInDialog"
      :cityworksIntegrationInfo="cityworksIntegrationInfo"
      :showUserCityworksSignInDialog="showUserCityworksSignInDialog"
      @cityworks-sign-in-dialog-close="showUserCityworksSignInDialog = false"
      @cityworks-sign-in-dialog-succeed="
        showUserCityworksSignInDialog = false;
        validateCityworksToken();
      "
    />
  </div>
</template>

<script>
import {
  mdiMap,
  mdiLayers,
  mdiClipboardCheck,
  mdiDomain,
  mdiOfficeBuildingMarker,
  mdiBookOpen,
  mdiHelpCircle,
  mdiChevronDown,
  mdiContacts,
  mdiLoginVariant,
  mdiLogoutVariant,
  mdiCameraIris,
  mdiMapLegend,
  mdiSyncAlert,
  mdiCogPlay,
  mdiChevronUp,
  mdiLayersPlus,
} from "@mdi/js";
import releaseNotes from "@/constants/releaseNotes";
import axios from "axios";
import UserCityworksSignInDialog from "@/components/shared/UserCityworksSignInDialog.vue";
import { mapMutations } from "vuex";

const APIURL = process.env.VUE_APP_API_URL;

export default {
  name: "NavDrawerList",
  computed: {
    versionNumber() {
      return this.releaseNotes[0].version;
    },
  },
  components: { UserCityworksSignInDialog },
  data() {
    return {
      mdiMap,
      mdiLayers,
      mdiClipboardCheck,
      mdiDomain,
      mdiOfficeBuildingMarker,
      mdiBookOpen,
      mdiHelpCircle,
      mdiChevronDown,
      mdiContacts,
      mdiLoginVariant,
      mdiLogoutVariant,
      mdiCameraIris,
      mdiMapLegend,
      mdiSyncAlert,
      mdiCogPlay,
      mdiChevronUp,
      mdiLayersPlus,
      currentUser: {},
      releaseNotes,
      cityworksAuthInvalid: true,
      showUserCityworksSignInDialog: false,
      showDataUtilitiesMenu: false,
    };
  },
  methods: {
    async signOutOfCityworks() {
      const auth = JSON.parse(localStorage.getItem("auth")) ?? {};
      const { user_id: userId } = auth;
      await axios.post(`${APIURL}/cityworks/sign_user_out`, undefined, {
        params: {
          user_id: userId,
        },
      });
      // eslint-disable-next-line no-unused-vars
      const { cityworks_token, ...newAuth } = auth;
      localStorage.setItem("auth", JSON.stringify(newAuth));
      await this.validateCityworksToken();
    },
    async validateCityworksToken() {
      if (!navigator.onLine) {
        return;
      }
      const auth = JSON.parse(localStorage.getItem("auth")) ?? {};
      const { cityworks_token: cityworksToken } = auth ?? {};
      if (!cityworksToken) {
        this.cityworksAuthInvalid = true;
        return;
      }
      try {
        const {
          data: { error },
        } = await axios.get(`${APIURL}/cityworks/validate_token`);
        this.cityworksAuthInvalid = Boolean(error);
      } catch (error) {
        this.cityworksAuthInvalid = true;
      } finally {
        if (this.cityworksAuthInvalid) {
          // eslint-disable-next-line no-unused-vars
          const { cityworks_token, ...newAuth } = auth;
          localStorage.setItem("auth", JSON.stringify(newAuth));
        }
      }
    },
    getUserData() {
      this.currentUser = JSON.parse(localStorage.getItem("auth"));
    },
    signOut() {
      const portalUrl = localStorage.getItem("portal-url");
      const hsBeacon = localStorage.getItem(
        "persist:hs-beacon-4abe865d-df2f-4cd6-84e8-e8ef9e1b965d"
      );
      localStorage.clear();
      this.showSignOutDialog = false;
      localStorage.setItem("portal-url", portalUrl);
      localStorage.setItem(
        "persist:hs-beacon-4abe865d-df2f-4cd6-84e8-e8ef9e1b965d",
        hsBeacon
      );
      this.$router.push("/login");
    },
    showChat() {
      if (!navigator.onLine) {
        return;
      }
      window.Beacon("destroy");
      window.Beacon("init", "4abe865d-df2f-4cd6-84e8-e8ef9e1b965d");
      window.Beacon("on", "ready", () => {
        window.Beacon("open");
        this.setBeaconOpen(true);
      });
      window.Beacon("on", "close", () => {
        this.setBeaconOpen(false);
      });
    },
    ...mapMutations(["setBeaconOpen"]),
  },
  mounted() {
    this.getUserData();
    window.Beacon("config", {
      mode: "askFirst",
    });
    window.Beacon("suggest");
    this.validateCityworksToken();
  },
  beforeUnmount() {
    window.Beacon("destroy");
  },
};
</script>

<style>
.overflow-y-auto {
  overflow-y: auto;
}

.min-button::before {
  display: none;
}
</style>

<style scoped>
.menu-text {
  font-weight: 500;
  max-width: 95%;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.nav-list-items {
  color: #286054;
  font-weight: 500;
}

.theme--light.v-navigation-drawer .v-divider {
  border-color: rgba(0, 0, 0, 0.25) !important;
}
</style>
