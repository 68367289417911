<template>
  <div v-if="!hideToolbar" class="d-flex justify-center top-center-element">
    <v-card :color="isRecording && !recordingPaused ? '#ff7777' : '#f2f2f2'">
      <v-card-text class="pa-1">
        <v-card elevation="0">
          <div class="d-flex">
            <div v-if="!isRecording & !startedRecording" class="d-flex">
              <v-btn icon @click="startRecording"
                ><v-icon color="#ff6060">{{ mdiRecord }}</v-icon></v-btn
              >
              <div style="border: 1px #eeeeee solid"></div>
              <v-btn icon @click="$emit('close-capture-toolbar')"
                ><v-icon>{{ mdiClose }}</v-icon></v-btn
              >
            </div>
            <div v-if="isRecording" class="d-flex">
              <v-btn icon @click="pauseResumeRecording">
                <v-icon>{{ pauseRecordIcon }}</v-icon>
              </v-btn>
              <div style="border: 1px #eeeeee solid"></div>

              <v-btn icon @click="stopRecording">
                <v-icon>{{ mdiStopCircle }}</v-icon>
              </v-btn>
            </div>
            <div v-if="isRecording" style="border: 1px #eeeeee solid"></div>
            <div
              v-if="startedRecording || isRecording"
              class="d-flex justify-center"
              style="height: 36px; width: 36px"
            >
              <div class="align-self-center">{{ countdownElement }}</div>
            </div>
          </div>
        </v-card>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import {
  mdiClose,
  mdiRecord,
  mdiPauseCircle,
  mdiPlayCircle,
  mdiStopCircle,
} from "@mdi/js";
import fixWebmDuration from "fix-webm-duration";

export default {
  name: "CaptureToolBar",
  props: {
    showCaptureToolbar: Boolean,
  },
  data() {
    return {
      mdiClose,
      mdiRecord,
      mdiPauseCircle,
      mdiPlayCircle,
      mdiStopCircle,
      mediaRecorder: null,
      recordedChunks: [],
      recordingPaused: false,
      isRecording: false,
      takingScreenshot: false,
      startedRecording: false,
      countdownElement: undefined,
      hideToolbar: false,
      captureMaxTime: 180,
      stopRecordingInterval: undefined,
      startTime: null,
      endTime: null,
      duration: 0,
    };
  },
  computed: {
    pauseRecordIcon() {
      return this.recordingPaused ? "mdi-play-circle" : "mdi-pause-circle";
    },
  },
  methods: {
    startRecording() {
      const displayOptions = { video: true };
      const audioOptions = { audio: true };

      navigator.mediaDevices
        .getDisplayMedia(displayOptions)
        .then((displayStream) => {
          navigator.mediaDevices
            .getUserMedia(audioOptions)
            .then((audioStream) => {
              const mergedStream = new MediaStream([
                ...displayStream.getTracks(),
                ...audioStream.getTracks(),
              ]);

              this.mediaRecorder = new MediaRecorder(mergedStream);
              this.mediaRecorder.addEventListener("dataavailable", (event) => {
                if (event.data.size > 0) {
                  this.recordedChunks.push(event.data);
                }
              });

              this.startedRecording = true;
              this.countdownElement = "3";

              let countdown = 3;
              const countdownInterval = setInterval(() => {
                countdown--;
                this.countdownElement = countdown;

                if (countdown === -1) {
                  clearInterval(countdownInterval);
                  this.countdownElement = "";
                  this.mediaRecorder.start();
                  this.isRecording = true;
                  this.startedRecording = false;

                  this.startTime = Date.now();

                  this.countdownElement = this.formatTime(this.captureMaxTime);

                  let countdown = this.captureMaxTime;
                  this.stopRecordingInterval = setInterval(() => {
                    if (!this.recordingPaused) {
                      countdown--;
                      this.countdownElement = this.formatTime(countdown);

                      if (countdown === -1) {
                        clearInterval(this.stopRecordingInterval);
                        this.stopRecording();
                      }
                    }
                  }, 1000);
                }
              }, 1000);
            })
            .catch((error) => {
              console.error("Error accessing audio media:", error);
            });
        })
        .catch((error) => {
          console.error("Error accessing screen media:", error);
        });
    },
    pauseResumeRecording() {
      if (this.mediaRecorder && this.mediaRecorder.state === "recording") {
        this.mediaRecorder.pause();
        this.endTime = Date.now();
        this.duration += this.endTime - this.startTime;
        this.recordingPaused = true;
      } else if (this.mediaRecorder && this.mediaRecorder.state === "paused") {
        this.mediaRecorder.resume();
        this.startTime = Date.now();
        this.recordingPaused = false;
      }
    },
    async stopRecording() {
      clearInterval(this.stopRecordingInterval);
      if (this.mediaRecorder && this.mediaRecorder.state !== "inactive") {
        this.mediaRecorder.stop();
        await this.mediaRecorder.addEventListener("stop", async () => {
          this.mediaRecorder = null;

          this.endTime = Date.now();

          this.duration += this.endTime - this.startTime;
          const blob = new Blob(this.recordedChunks, { type: "video/webm" });
          await fixWebmDuration(blob, this.duration, {
            logger: false,
          }).then((fixedBlob) => {
            const videoURL = URL.createObjectURL(fixedBlob);
            this.$emit("finished-recording", videoURL);
          });

          this.recordedChunks = [];
        });
      }
      this.isRecording = false;
      this.countdownElement = "";
    },
    formatTime(timeInSeconds) {
      const minutes = Math.floor(timeInSeconds / 60);
      const seconds = timeInSeconds % 60;
      return `${minutes.toString().padStart(1, "0")}:${seconds
        .toString()
        .padStart(2, "0")}`;
    },
  },
};
</script>

<style>
.top-center-element {
  margin-top: 6px;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9999;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}
</style>
