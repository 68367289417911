<template>
  <v-app>
    <v-navigation-drawer
      stateless
      :bottom="$vuetify.breakpoint.xsOnly"
      app
      color="#f1f2f1"
      :value="isMenuOpen"
      v-click-outside="onClickOutside"
      v-if="!$route.path.includes('login')"
    >
      <template v-slot:prepend>
        <v-list-item>
          <img src="@/assets/UtiliSync_Admin_Logo.svg" id="logo" />
        </v-list-item>
      </template>

      <NavDrawerList
        v-if="isMenuOpen"
        @open-capture-upload-dialog="showCaptureOrUploadDialog = true"
      />
    </v-navigation-drawer>

    <ErrorDialog :showErrorDialog="errorOccurred" />

    <BadRequestErrorDialog
      :showBadRequestErrorDialog="Boolean(badRequestError)"
    />

    <CaptureOrUploadDialog
      v-if="showCaptureOrUploadDialog"
      @capture-upload-dialog-close="showCaptureOrUploadDialog = false"
      @open-capture-toolbar="
        showCaptureOrUploadDialog = false;
        showCaptureToolbar = true;
      "
      :showCaptureOrUploadDialog="showCaptureOrUploadDialog"
    />

    <CaptureShowWidget
      v-if="showCaptureShowWidget"
      @capture-show-dialog-close="
        videoURL = undefined;
        showCaptureShowWidget = false;
      "
      @close-capture-toolbar="showCaptureToolbar = false"
      :showCaptureShowWidget="showCaptureShowWidget"
      :videoURL="videoURL"
    />

    <CaptureToolBar
      v-if="showCaptureToolbar"
      @close-capture-toolbar="showCaptureToolbar = false"
      @finished-recording="
        videoURL = $event;
        showCaptureShowWidget = true;
      "
    />

    <slot></slot>
  </v-app>
</template>

<script>
import "@mdi/font/css/materialdesignicons.css";
import NavDrawerList from "@/components/app/NavDrawerList.vue";
import { mapGetters, mapMutations } from "vuex";
import ErrorDialog from "@/components/shared/ErrorDialog";
import BadRequestErrorDialog from "@/components/shared/BadRequestErrorDialog";
import CaptureToolBar from "@/components/app/capture/CaptureToolBar";
import CaptureShowWidget from "@/components/app/capture/CaptureShow";
import CaptureOrUploadDialog from "@/components/app/capture/CaptureOrUpload";
import { setDefaultOptions } from "esri-loader";
import moment from "moment";

setDefaultOptions({ version: "4.26" });

export default {
  name: "MainLayout",
  components: {
    NavDrawerList,
    ErrorDialog,
    BadRequestErrorDialog,
    CaptureToolBar,
    CaptureShowWidget,
    CaptureOrUploadDialog,
  },
  data() {
    return {
      showCaptureToolbar: false,
      videoURL: undefined,
      showCaptureShowWidget: false,
      showCaptureOrUploadDialog: false,
      beaconTimer: undefined,
    };
  },
  computed: {
    ...mapGetters([
      "isMenuOpen",
      "errorOccurred",
      "badRequestError",
      "isBeaconOpen",
    ]),
  },
  async beforeMount() {
    this.beaconTimer = setInterval(() => {
      this.refreshBeacon();
    }, 30 * 1000);
  },
  methods: {
    onClickOutside(ev) {
      if (ev.target.className === "v-overlay__scrim") {
        this.setMenuState(false);
      }
    },
    refreshBeacon() {
      if (!this.isBeaconOpen) {
        const auth = JSON.parse(localStorage.getItem("auth"));
        const {
          email,
          user_id: userId,
          username,
          f_name: fName,
          l_name: lName,
          user_created: userCreated,
          is_gis_admin: isGisAdmin,
          is_locate_request_user: isLocateRequestUser,
          is_power_user: isPowerUser,
          is_view_only_user: isViewOnlyUser,
          is_sys_admin: isSysAdmin,
          is_standard_user: isStandardUser,
          organization,
          last_seen: lastSeen,
        } = auth ?? {};

        const props = {
          email: email ?? "",
          user_id: userId,
          username,
          name: `${fName} ${lName}`,
          user_created: moment(userCreated * 1000).format("YYYY-MM-DD"),
          is_admin: isGisAdmin?.toString(),
          is_locate_request_user: isLocateRequestUser?.toString(),
          is_power_user: isPowerUser?.toString(),
          is_view_only_user: isViewOnlyUser?.toString(),
          is_sys_admin: isSysAdmin?.toString(),
          is_standard_user: isStandardUser?.toString(),
          company: organization?.name,
          organization_gis_connection_type: organization?.gis_connection_type,
          domain: window.location.hostname,
          app_version: this.versionNumber,
          last_seen: moment(lastSeen).format("YYYY-MM-DD"),
        };
        window.Beacon("destroy");
        window.Beacon("init", "4abe865d-df2f-4cd6-84e8-e8ef9e1b965d");
        window.Beacon("identify", props);
        window.Beacon("event", {
          type: "page-viewed",
          url: document.location.href,
          title: document.title,
        });
      }
    },
    ...mapMutations(["setMenuState"]),
  },
};
</script>

<style>
html {
  overflow-y: auto;
}
/* Remove empty scrollbar */

.mainArea {
  position: absolute;
  top: 56px;
  /* move to be below toolbar */
  width: 100%;
  height: calc(100% - 56px);
  overflow: auto;
}

.page-background {
  background-color: #f1f2f1;
  min-height: calc(100vh - 56px);
}

.page-padding {
  padding: 16px;
}

.cursor-pointer {
  cursor: pointer;
}

.button,
.top-bar {
  background-image: linear-gradient(to right, #286054, #093637);
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-grab {
  cursor: grab;
}

.gap {
  gap: 5px;
}

.w-100 {
  width: 100%;
}
</style>

<style scoped>
#logo {
  width: 78%;
  padding-top: 24px;
  margin: 0 auto;
}
</style>
