var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    [
      !_vm.$route.path.includes("login")
        ? _c(
            "v-navigation-drawer",
            {
              directives: [
                {
                  name: "click-outside",
                  rawName: "v-click-outside",
                  value: _vm.onClickOutside,
                  expression: "onClickOutside",
                },
              ],
              attrs: {
                stateless: "",
                bottom: _vm.$vuetify.breakpoint.xsOnly,
                app: "",
                color: "#f1f2f1",
                value: _vm.isMenuOpen,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "prepend",
                    fn: function () {
                      return [
                        _c("v-list-item", [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/UtiliSync_Admin_Logo.svg"),
                              id: "logo",
                            },
                          }),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                2130471254
              ),
            },
            [
              _vm.isMenuOpen
                ? _c("NavDrawerList", {
                    on: {
                      "open-capture-upload-dialog": function ($event) {
                        _vm.showCaptureOrUploadDialog = true
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c("ErrorDialog", { attrs: { showErrorDialog: _vm.errorOccurred } }),
      _c("BadRequestErrorDialog", {
        attrs: { showBadRequestErrorDialog: Boolean(_vm.badRequestError) },
      }),
      _vm.showCaptureOrUploadDialog
        ? _c("CaptureOrUploadDialog", {
            attrs: { showCaptureOrUploadDialog: _vm.showCaptureOrUploadDialog },
            on: {
              "capture-upload-dialog-close": function ($event) {
                _vm.showCaptureOrUploadDialog = false
              },
              "open-capture-toolbar": function ($event) {
                _vm.showCaptureOrUploadDialog = false
                _vm.showCaptureToolbar = true
              },
            },
          })
        : _vm._e(),
      _vm.showCaptureShowWidget
        ? _c("CaptureShowWidget", {
            attrs: {
              showCaptureShowWidget: _vm.showCaptureShowWidget,
              videoURL: _vm.videoURL,
            },
            on: {
              "capture-show-dialog-close": function ($event) {
                _vm.videoURL = undefined
                _vm.showCaptureShowWidget = false
              },
              "close-capture-toolbar": function ($event) {
                _vm.showCaptureToolbar = false
              },
            },
          })
        : _vm._e(),
      _vm.showCaptureToolbar
        ? _c("CaptureToolBar", {
            on: {
              "close-capture-toolbar": function ($event) {
                _vm.showCaptureToolbar = false
              },
              "finished-recording": function ($event) {
                _vm.videoURL = $event
                _vm.showCaptureShowWidget = true
              },
            },
          })
        : _vm._e(),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }