import axios from "axios";
import store from "@/store";

const APIURL = process.env.VUE_APP_API_URL;

const axiosWithNoAuth = axios.create();

axios.interceptors.request.use(
  (config) => {
    if (config.url.includes(APIURL) && !config.url.includes("get_token")) {
      config.headers["X-AUTH"] = localStorage.getItem("token");
      return config;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const URLS_TO_IGNORE = [
  "refresh_token",
  "esri_login",
  "get_client_id",
  "inspection_templates",
  "inspection_QA",
  "entity_types",
  "work_order_templates",
  "domains",
  "form_definitions",
];

axios.interceptors.response.use(null, async (error) => {
  if (error.response.status === 500) {
    store.commit("setErrorOccurred", true);
  } else if ([400, 403].includes(error.response.status)) {
    if (
      URLS_TO_IGNORE.every((u) => !error.response?.config?.url?.includes?.(u))
    ) {
      if (Array.isArray(error.response?.data?.error)) {
        store.commit("setBadRequestError", error.response?.data?.error);
      } else {
        store.commit("setBadRequestError", [error.response?.data?.error]);
      }
    }
  } else if (error.response.status === 401) {
    if (!localStorage.getItem("auth")) {
      return Promise.reject(error);
    }

    try {
      const data = await axios.post(`${APIURL}/auth/refresh_token`, {
        refresh_token: localStorage.getItem("refresh_token"),
      });
      const {
        data: {
          results: { token },
        },
      } = data;
      localStorage.setItem("auth", JSON.stringify(data.data.results));
      localStorage.setItem("token", token);
      return axios.request(error.config);
    } catch (error) {
      const portalUrl = localStorage.getItem("portal-url");
      const hsBeacon = localStorage.getItem(
        "persist:hs-beacon-4abe865d-df2f-4cd6-84e8-e8ef9e1b965d"
      );
      localStorage.clear();
      localStorage.setItem("portal-url", portalUrl);
      localStorage.setItem(
        "persist:hs-beacon-4abe865d-df2f-4cd6-84e8-e8ef9e1b965d",
        hsBeacon
      );
      window.location.href = "/login";
      return Promise.reject(error);
    }
  }
  return Promise.reject(error);
});

export { axiosWithNoAuth };
