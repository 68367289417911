export default [
  {
    version: "0.57",
    date: "December 13, 2024",
    summary:
      "Additional features, updates to an existing feature, and a bug fix as described below:",
    notes: [
      {
        type: "Feature",
        title: "1. Workflows Updates (DEV-1868, DEV-1887)",
        description:
          "Added the ability to set Cityworks service request priority via workflows. Email notifications can now be toggled between rich text and plain text.",
      },
      {
        type: "Feature",
        title: "2. Doc Builder (DEV-1896)",
        description:
          "The form Doc Builder now matches the generated PDF more closely.",
      },
      {
        type: "Bug",
        title: "3. Feature Service Conversion Bug (DEV-1895)",
        description:
          "Fixed an issue where the conversion of a feature service did not move all form submissions to the new layer as expected.",
      },
    ],
  },
  {
    version: "0.56",
    date: "November 21, 2024",
    summary:
      "Additional features, updated to existing features, and a bug fixes as described below:",
    notes: [
      {
        type: "Feature",
        title: "1. Browser Autofill Removed From Dropdown Fields (DEV-1872)",
        description:
          "Removed the browser autofill from dropdown fields as it was blocking the dropdown options from being visible.",
      },
      {
        type: "Feature",
        title: "2. New Workflow Trigger - Ticket Assignment (DEV-1809)",
        description:
          "Added a new workflow trigger for when a ticket is assigned to a user.",
      },
      {
        type: "Feature",
        title: "3. Workflow Action - Auto Close Tickets (DEV-1530)",
        description:
          "Added a new workflow action for being able to auto close tickets.",
      },
      {
        type: "Feature",
        title: "4. Workflow Notification Data - Ticket XML (DEV-1810)",
        description:
          "Added the ability to use ticket XML data in workflow notifications.",
      },
      {
        type: "Bug",
        title: "5. UtiliSync Layers Not Deleting (DEV-1873)",
        description:
          "Fixed an issue where UtiliSync layers were not being deleted as expected, even brand new layers with no other dependencies.",
      },
      {
        type: "Bug",
        title: "6. Add Labor to Service Request UtiliBot Bug (DEV-1812)",
        description:
          "Fixed an issue where the Add Labor to Service Request UtiliBot was not adding labor to the service request as expected when using a multi-select field for the time type.",
      },
      {
        type: "Bug",
        title: "7. Layers Not Updating Correctly (DEV-1869)",
        description:
          "Fixed an issue where layers were not updating correctly when editing a layer.",
      },
      {
        type: "Bug",
        title: "8. Convert GIS Layer to UtiliSync Layer (DEV-1864)",
        description:
          "Fixed an issue where the conversion of a GIS layer to a UtiliSync layer was not taking into account the projection of the GIS layer.",
      },
      {
        type: "Bug",
        title: "10. UtiliSync Layer Creation (DEV-1866)",
        description:
          'Fixed an issue where the creation of a UtiliSync layer was showing two "thanks" messages upon completion.',
      },
    ],
  },
  {
    version: "0.55",
    date: "November 11, 2024",
    summary: "Additional feature as described below:",
    notes: [
      {
        type: "Feature",
        title: "1. Positive Response Testing (DEV-1852)",
        description:
          "Added the ability to test positive response. Tickets are closed and the positive response payload returned to the user and not submitted to the one-call center. Used for testing purposes only.",
      },
    ],
  },
  {
    version: "0.54",
    date: "November 8, 2024",
    summary: "Additional feature as described below:",
    notes: [
      {
        type: "Feature",
        title: "1. To Cityworks Inspection Date (DEV-1858)",
        description:
          "Can now set the inspection date for Cityworks inspections.",
      },
      {
        type: "Feature",
        title: "2. Cityworks Handling (DEV-1861)",
        description:
          "Added better handling for Cityworks integration configuration.",
      },
      {
        type: "Feature",
        title: "3. Default Custom Positive Response Comment (DEV-1844)",
        description:
          "Can now configure the default comment for positive response comments in the UtiliSync Admin app.",
      },
      {
        type: "Bug",
        title:
          "4. Feature Service Layer To UtiliSync Layer Conversion (DEV-1860)",
        description:
          "Fixed an issue where date fields on a feature service layer were not being converted to UtiliSync date fields as expected.",
      },
    ],
  },
  {
    version: "0.53",
    date: "November 1, 2024",
    summary:
      "Additional feature and updates to an existing feature as described below:",
    notes: [
      {
        type: "Feature",
        title: "1. Add Labor to Service Request UtiliBot (DEV-1812)",
        description:
          "Added the ability to add labor to Cityworks service requests based off an employee id, time type, and hours worked.",
      },
      {
        type: "Feature",
        title: "2. Cityworks 811 Configuration UI Updates (DEV-1815)",
        description:
          "Updated the Cityworks 811 configuration UI to be more user friendly.",
      },
    ],
  },
  {
    version: "0.52",
    date: "October 17, 2024",
    summary: "Updates to an existing feature and a bug fix as described below:",
    notes: [
      {
        type: "Feature",
        title: "1. Added Photo Metadata Toggle (DEV-1777)",
        description:
          "Added a toggle to the configuration of photo type fields to be able to turn on the addition of photo metadata to the photo. ",
      },
      {
        type: "Bug",
        title: "2. Cityworks Sign In (DEV-1821)",
        description:
          "Fixed an issue where the Cityworks log-in with the ArcGIS Enterprise single-sign-on was not working.",
      },
    ],
  },
  {
    version: "0.51",
    date: "October 2, 2024",
    summary: "Updates to an existing features as described below:",
    notes: [
      {
        type: "Feature",
        title:
          "1. Moved Add Choice Button for Single and Multi Select Fields (DEV-1820)",
        description:
          "Moved the 'Add Choice' button for single and multi select fields to make it look actionable in association with the list of choices.",
      },
    ],
  },
  {
    version: "0.50",
    date: "September 26, 2024",
    summary:
      "Additional features and updates to an existing feature as described below:",
    notes: [
      {
        type: "Feature",
        title: "1. Admin Permissions (DEV-1656)",
        description:
          "Admins now have additional permissions. Permissions can now be set for being able to edit users, maps, layers, forms, and 811 codes.",
      },
      {
        type: "Feature",
        title: "2. Inspect Permission Addition (DEV-1484)",
        description:
          "Admins can now toggle a user's permission of being able to edit scheduled form tasks.",
      },
      {
        type: "Feature",
        title: "3. From Cityworks UtiliBot Due Date (DEV-1802)",
        description:
          "Added support to be able to set the due date of the scheduled form task that gets generated from a Cityworks webhook.",
      },
    ],
  },
  {
    version: "0.49",
    date: "September 13, 2024",
    summary: "Bug fixes as described below:",
    notes: [
      {
        type: "Feature",
        title: "1. From Cityworks UtiliBot Fix (DEV-1782)",
        description:
          "Fixed an issue where the From Cityworks UtiliBot was not creating a scheduled form task as expected due to the Cityworks Activity ID already being present on another task. Now, we check the specific layer to see if the Cityworks Activity ID already exists on a scheduled form task.",
      },
    ],
  },
  {
    version: "0.48",
    date: "September 10, 2024",
    summary: "Updates to an existing feature as described below:",
    notes: [
      {
        type: "Feature",
        title:
          "1. Scheduled Form UtiliBot Updates (DEV-494, DEV-1508, DEV-1767, DEV-1768)",
        description:
          "Major updates to the Scheduled Form UtiliBot. Now, you can configure multiple scheduled form tasks to be created based off of a single form submission. You can now select the form that will be scheduled. You can now use a date form field to set the due date of the scheduled form task. You can now use a date form field to be used as the date for which the 'Days From' bases its date off of to compute the due date. You can now select a notification template that gets associated with the scheduled form task.",
      },
    ],
  },
  {
    version: "0.47",
    date: "August 30, 2024",
    summary:
      "Additional features, updates to existing features, and a bug fix as described below:",
    notes: [
      {
        type: "Feature",
        title: "1. From Cityworks UtiliBot (DEV-85)",
        description:
          "Added the ability to be able to generate a scheduled form task in UtiliSync when an Inspection is created in Cityworks that triggers a webhook.",
      },
      {
        type: "Feature",
        title: "2. Renaming of 811 Accounts (DEV-1753)",
        description:
          "Updated the name of '811 Accounts' to be '811 Codes' as this more closely matches the naming of one-call centers.",
      },
      {
        type: "Bug",
        title:
          "3. Standard Ticket Notifications Sending When Off For Users (DEV-1763)",
        description:
          "Fixed an issue where standard ticket notifications were being sent to users even when '811 Ticket Notifications' was turned off for the user.",
      },
    ],
  },
  {
    version: "0.46",
    date: "August 15, 2024",
    summary: "Bug fix as described below:",
    notes: [
      {
        type: "Bug",
        title: "1. Admin Toggle - Edit User Dialog (DEV-1739)",
        description:
          "Fixed an issue where the Admin toggle button in the Edit User dialog was not saving back to the user data.",
      },
    ],
  },
  {
    version: "0.45",
    date: "August 8, 2024",
    summary:
      "Additional feature and updates to an existing feature as described below:",
    notes: [
      {
        type: "Feature",
        title: "1. Auto-Close Cancel Tickets (DEV-1543)",
        description:
          "Now 811 Codes for all one-call centers can toggle on 'Auto-Close Cancel Tickets' which will auto close tickets where a cancel ticket is received.",
      },
      {
        type: "Feature",
        title: "2. In-App Alerts (DEV-1698)",
        description:
          "In-app alerts now appear without having to reload the app.",
      },
    ],
  },
  {
    version: "0.44",
    date: "July 25, 2024",
    summary: "Updates to an existing feature as described below:",
    notes: [
      {
        type: "Feature",
        title: "1. Facilities Update (DEV-1719)",
        description:
          "Facilities are now added via individual 811 Codes instead of on the positive response field on locate request forms.",
      },
    ],
  },
  {
    version: "0.43",
    date: "July 23, 2024",
    summary:
      "Additional features, updates to an existing feature, and a bug fix as described below:",
    notes: [
      {
        type: "Feature",
        title: "1. Workflows Added for JULIE One-Call Center (DEV-1712)",
        description:
          "Workflows can now be configured for the JULIE one-call center.",
      },
      {
        type: "Feature",
        title: "2. Workflows Added for Georgia811 One-Call Center (DEV-1671)",
        description:
          "Workflows can now be configured for the Georgia811 one-call center.",
      },
      {
        type: "Feature",
        title: "3. ArcGIS Login Failure Dialog (DEV-1705)",
        description:
          "Updated the ArcGIS login failure dialog to display the error message from the server to provide better information for the login failure.",
      },

      {
        type: "Bug",
        title: "4. Blank Login Failure Dialog (DEV-1702)",
        description:
          "Fixed an issue where, in some cases, a blank error dialog would appear upon failure to login.",
      },
    ],
  },
  {
    version: "0.42",
    date: "June 27, 2024",
    summary: "Updates to an existing feature as described below:",
    notes: [
      {
        type: "Bug",
        title: "1. Email Default Value Validation (DEV-1628)",
        description:
          "Updated the email field's custom default value to be validated so that only valid email addresses could be input.",
      },
    ],
  },
  {
    version: "0.41",
    date: "June 18, 2024",
    summary: "Updates to an existing feature as described below:",
    notes: [
      {
        type: "Feature",
        title: "1. Departments (DEV-1598)",
        description:
          "Added ability to create departments, which affects the Inspect app. Departments are a way to separate out maps, layers, forms, scheduled form tasks, and action items, between different departments within the same organization. Reach out to support to learn more.",
      },
    ],
  },
  {
    version: "0.40",
    date: "June 3, 2024",
    summary: "Updates to an existing feature as described below:",
    notes: [
      {
        type: "Feature",
        title: "1. Show Code OH811 (DEV-1597)",
        description:
          "The 'Show Code' field is no longer required to be able to create an Ohio811 811 Code.",
      },
    ],
  },
  {
    version: "0.39",
    date: "May 24, 2024",
    summary: "Bug fix as described below:",
    notes: [
      {
        type: "Bug",
        title: "1. Browse ArcGIS Services Import Issue (DEV-1597)",
        description:
          "Fixed an issue where in the 'Browse ArcGIS Services' dialog, clicking on the dropdown arrow for a service would not load the layers on that service. Now, clicking on the row of the table or the dropdown arrow will load the layers for a service.",
      },
    ],
  },
  {
    version: "0.38",
    date: "May 22, 2024",
    summary: "Updates to existing features and bug fixes as described below:",
    notes: [
      {
        type: "Feature",
        title: "1. Secured Basemaps (DEV-1556)",
        description:
          "Updated creation and editing of basemaps to allow for toggling whether or not the basemap service is secured or not. Also added the ability to be able to test the connection of a basemap.",
      },
      {
        type: "Feature",
        title: "2. Edit User Dialog (DEV-1601)",
        description:
          "Updated the 'Permissions' section of the 'Edit User' dialog. Section is now split by app, and only shown if the organization has access to the specific app. The 'Notifications' section has been renamed 'Notifications - UtiliSync Inspect' and now only appears if the organization has access to the Inspect app.",
      },
      {
        type: "Bug",
        title: "3. Error Deleting Forms (DEV-1561)",
        description:
          "Fixed an issue where forms that were unable to be deleted would return an error. Now, when a form is unable to be deleted, a dialog will appear to inform the user.",
      },
      {
        type: "Bug",
        title: "4. Incorrect Login Display (DEV-1582)",
        description:
          "Fixed an issue where if a user entered the wrong credentials in the standard sign-in, no message was displayed informing the user.",
      },
    ],
  },
  {
    version: "0.37",
    date: "May 20, 2024",
    summary: "Updates to existing features as described below:",
    notes: [
      {
        type: "Feature",
        title: "1. Customized GIS Field Ordering Sync (DEV-1153)",
        description:
          "Added a button sync a GIS layer with its customized configuration in UtiliSync. Adds fields that have been added to the GIS layer to the GIS Fields configuration and removes fields that no longer exist from the GIS Fields configuration.",
      },
      {
        type: "Feature",
        title: "2. Screen Capture Duration (DEV-1541)",
        description:
          "Previously, there was no duration added to the screen recorded videos created using the 'Screen Capture' tool. Now, there is a duration added to the video allowing users to be able to seek to any position in the video and see the total time of the video.",
      },
    ],
  },
  {
    version: "0.36",
    date: "May 14, 2024",
    summary:
      "Additional feature, updates to an existing feature, and a bug fix as described below:",
    notes: [
      {
        type: "Feature",
        title: "1. Paragraph Rich Text (DEV-1553)",
        description:
          "Added a toggle to the configuration of paragraph fields to be able to turn off rich text.",
      },
      {
        type: "Feature",
        title: "2. ArcGIS Login Failure Message (DEV-1547)",
        description:
          "Added a more descriptive error message when a valid login is supplied via the ArcGIS login doesn't match up with a user in the UtiliSync system.",
      },
      {
        type: "Bug",
        title: "3. Schedule Form Configuration (DEV-1533)",
        description:
          "Fixed an issue where the Schedule Form UtiliBot configuration would show 'Conditional' when 'Never' was selected. Added additional requirements to the setup of the UtiliBot before it can be saved successfully.",
      },
    ],
  },
  {
    version: "0.35",
    date: "April 26, 2024",
    summary: "Bug fixes as described below:",
    notes: [
      {
        type: "Bug",
        title: "1. Workflow SMS Users List (DEV-1544)",
        description:
          "Fixed an issue where users without a phone number added were appearing in the SMS users list within 'Workflows'.",
      },
      {
        type: "Bug",
        title: "2. Duplicating Tickets Field Fix (DEV-1533)",
        description:
          "Fixed an issue where the 'Duplicate Ticketes to these Accounts' field within Diggers Hotline 811 Codes was not displaying the accounts to duplicate to as expected.",
      },
    ],
  },
  {
    version: "0.34",
    date: "April 11, 2024",
    summary: "Updates to an existing feature as described below:",
    notes: [
      {
        type: "Feature",
        title: "1. Password Reset Dialog Clarification (DEV-1532)",
        description:
          "Updated the password reset dialog to include a clarifying description of the account type is is used for and what needs to be input to recieve a password reset email.",
      },
    ],
  },
  {
    version: "0.34",
    date: "April 11, 2024",
    summary: "Updates to existing features and a bug fix as described below:",
    notes: [
      {
        type: "Update",
        title: "1. ArcGIS Related Table Tab (DEV-116)",
        description:
          "Updated the ArcGIS Related Table tab to display data even if the user isn't signed in with ArcGIS.",
      },
      {
        type: "Bug",
        title: "2. User Licensing Bug (DEV-1475)",
        description:
          "Fixed an issue were inactive users were being counted towards user type totals making it so that in some cases the user type for a user was unable to be updated.",
      },
    ],
  },
  {
    version: "0.33",
    date: "March 1, 2024",
    summary: "Bug fix as described below:",
    notes: [
      {
        type: "Bug",
        title: "1. Facility Name Bug (DEV-1428)",
        description:
          "Fixed an issue where the positive response failed if there was a space in the facility name for Diggers Hotline. Now, positive response works for all facility names.",
      },
    ],
  },
  {
    version: "0.32",
    date: "February 28, 2024",
    summary: "Updates to an existing feature as described below:",
    notes: [
      {
        type: "Feature",
        title: "1. Diggers Hotline Facility Requirement (DEV-1428)",
        description:
          "Facility is now required for Diggers Hotline Positive Response form fields.",
      },
    ],
  },
  {
    version: "0.31",
    date: "February 26, 2024",
    summary: "Updates to an existing feature and a bug fix as described below:",
    notes: [
      {
        type: "Feature",
        title: "1. Make Show Code Required For Ohio811 (DEV-1390)",
        description:
          "Show code is now required for Ohio when adding and editing Ohio 811 Codes.",
      },
      {
        type: "Bug",
        title: "2. Error Creating User (DEV-1429)",
        description:
          "Fixed an issue where admins could not create new users. Now, admins can create new users as expected.",
      },
    ],
  },
  {
    version: "0.30",
    date: "February 22, 2024",
    summary: "Additional feature and a bug fix as described below:",
    notes: [
      {
        type: "Feature",
        title: "1. TMS Dashboard Access (DEV-1389)",
        description:
          "A new user setting has been added to be able to toggle access to the Dashboard page within the TMS app.",
      },
      {
        type: "Bug",
        title: "2. Visibility Settings on Date Fields (DEV-1419)",
        description:
          "Fixed an issue where visibility could not be set in some cases for 'Date' fields as expected.",
      },
    ],
  },
  {
    version: "0.29",
    date: "February 14, 2024",
    summary: "Additional feature as described below:",
    notes: [
      {
        type: "Feature",
        title: "1. Convert Feature Service Layer to UtiliSync Layer (DEV-1125)",
        description:
          'Added the "Convert Feature Service" feature to easily convert a Feature Service layer to a UtiliSync layer.',
      },
    ],
  },
  {
    version: "0.28",
    date: "February 8, 2024",
    summary: "Updates to an existing feature as described below:",
    notes: [
      {
        type: "Feature",
        title: "1. Diggers Hotline Ticket Duplication (DEV-1395)",
        description:
          "Configuration added to take one received ticket to be duplicated to separate 811 Codes.",
      },
    ],
  },
  {
    version: "0.27",
    date: "February 2, 2024",
    summary: "Updates to existing features as described below:",
    notes: [
      {
        type: "Feature",
        title: "1. Missing Hint Fields (DEV-1376)",
        description:
          "Updated some UtiliSync form fields that were missing their 'Hint' fields.",
      },
    ],
  },
  {
    version: "0.26",
    date: "January 30, 2024",
    summary:
      "Additional features, updates to existing features, and bug fixes as described below:",
    notes: [
      {
        type: "Bug",
        title: "1. Text Formatting Issue (DEV-1361)",
        description:
          "Fixed an issue where bold and italic text formatting was not displaying on the PDF. Now, bold and italics are displayed on the PDF.",
      },
    ],
  },
  {
    version: "0.25",
    date: "January 19, 2024",
    summary:
      "Additional features, updates to existing features, and bug fixes as described below:",
    notes: [
      {
        type: "Feature",
        title: '1. "Show Inserted In Doc" Functionality (DEV-771)',
        description:
          'Fixed an issue where some fields weren\'t counted in the "Show Inserted In Doc" functionality. Now, all fields are counted.',
      },
    ],
  },
  {
    version: "0.24",
    date: "January 17, 2024",
    summary:
      "Additional features, updates to existing features, and bug fixes as described below:",
    notes: [
      {
        type: "Feature",
        title: "1. Update ObjectID Tool (DEV-1305)",
        description:
          'Added the "Update ObjectIDs" feature to easily import and update ObjectIDs.',
      },
    ],
  },
  {
    version: "0.23",
    date: "January 2, 2024",
    summary: "Bug fix as described below:",
    notes: [
      {
        type: "Bug",
        title: "1. GIS Fields (DEV-1329)",
        description:
          "Fixed an issue where GIS fields would not get retrieved as expected if the service was unsecure and the user was signed in with an ArcGIS user.",
      },
    ],
  },
  {
    version: "0.22",
    date: "December 13, 2023",
    summary: "Bug fix as described below:",
    notes: [
      {
        type: "Bug",
        title: "1. Feature Service Reference Field (DEV-1294)",
        description:
          "Fixed an issue where the reference field for Feature Service layers were not getting set as expected.",
      },
    ],
  },
  {
    version: "0.21",
    date: "November 29, 2023",
    summary:
      "Additional features, updates to existing features, and bug fixes as described below:",
    notes: [
      {
        type: "Bug",
        title: "1. Auto-Assign Ticket Display Information (DEV-1248)",
        description:
          "Fixed an issue where the auto-assign ticket region and user name was not showing. Now, the fields display as expected.",
      },
      {
        type: "Update",
        title: "2. Cityworks Service Request Update (DEV-1205)",
        description:
          "Updated the Cityworks service request integration for new 811 tickets. Now, the WKID is defined every time a service request is created, allow the ticket coordinates to be updated, if needed.",
      },
    ],
  },
  {
    version: "0.20",
    date: "November 27, 2023",
    summary: "Updates to an existing feature and a bug fix as described below:",
    notes: [
      {
        type: "Feature",
        title: "1. Show Licensing For Cityworks Integration (DEV-1267)",
        description:
          "Licensing and Usage now shows if an organization has licensing Cityworks Integration for The UtiliSync 811 and Inspect apps.",
      },
      {
        type: "Bug",
        title: "2. User Password Creation Email (DEV-1242)",
        description:
          "Fixed an issue where the email to create a password that is sent after user creation would take the new user to the wrong app. Now, the admin creating the new user is asked which app the user will be using and the password creation link will redirect to the app selected. Additionally, if the organization has an ArcGIS Online or Enterprise integration set up and an ArcGIS username has been added to the user created, there no longer will be a password creation link included in the email, rather instructions on how to sign in.",
      },
    ],
  },
  {
    version: "0.19",
    date: "November 16, 2023",
    summary: "Bug fix as described below:",
    notes: [
      {
        type: "Bug",
        title: "1. Custom Layer Symbology Issues (DEV-1131)",
        description:
          "Fixed an issue where the custom layer symbology configuration was missing certain display types on layers.",
      },
    ],
  },
  {
    version: "0.18",
    date: "November 10, 2023",
    summary: "Updates to existing feature and a bug fix as described below:",
    notes: [
      {
        type: "Feature",
        title:
          "1. For Information Only Logic and Requirement Settings (DEV-71)",
        description:
          "Updated the 'Required' dropdown field to be disabled when the 'For Information Only' setting was set to 'True'.",
      },
      {
        type: "Feature",
        title: "2. Licensing and Usage Updates (DEV-76)",
        description:
          "Usage bars now turn red when the limit is exceeded. Also. fixed an issue where the 'Renewal Date' did not get converted as expected.",
      },
      {
        type: "Bug",
        title: "3. Defaulting From GIS Field Setup (DEV-448)",
        description:
          "Fixed an issue where the 'GIS Field' input was always shown as a dropdown. Now, when the user is not signed in with ArcGIS and the layer is a secured feature service, the field will show as a text input.",
      },
    ],
  },
  {
    version: "0.17",
    date: "November 6, 2023",
    summary: "Updates to an existing feature as described below:",
    notes: [
      {
        type: "Feature",
        title: "1. Added 'Reload' Button to Login Page (DEV-1230)",
        description:
          "Added 'Reload' button to the Login page so users can reload the app.",
      },
    ],
  },
  {
    version: "0.16",
    date: "October 31, 2023",
    summary:
      "Additional feature and updates to an existing feature as described below:",
    notes: [
      {
        type: "Feature",
        title: "1. Licensing and Usage Information (DEV-76)",
        description:
          "Licensing and usage data is now displayed on the 'Organization' page to help admins keep track of their organization's usage.",
      },
      {
        type: "Feature",
        title: "2. Added WKID to Cityworks Integration (DEV-1205)",
        description:
          "Made WKID configurable in 'Save 811 Tickets to Cityworks Service Request' so it can be set independently for each organization.",
      },
    ],
  },
  {
    version: "0.15",
    date: "October 10, 2023",
    summary: "Bug fix as described below:",
    notes: [
      {
        type: "Bug",
        title: "1. Enterprise URL Input Issue (DEV-1156)",
        description:
          "Fixed an issue where on the Login page of the app, each time the user typed into the Enterprise URL input an error would appear if the URL was not complete. Now, the dialog no longer appears and the error message is displayed under the field.",
      },
    ],
  },
  {
    version: "0.14",
    date: "October 5, 2023",
    summary:
      "Additional features, updates to existing features, and bug fixes as described below:",
    notes: [
      {
        type: "Feature",
        title: "1. Added 'Hint' to Single and Multi Select Fields (DEV-1156)",
        description:
          "Single and Multi Select fields now show hints if added in the form field configuration.",
      },
      {
        type: "Feature",
        title: "2. Added 'Meet Date' Workflow Ticket Data (DEV-1161)",
        description:
          "The 'Meet Date' info can now be added to workflow notifications.",
      },
      {
        type: "Bug",
        title: "3. Paragraph Field Formatting (DEV-1081)",
        description:
          "Fixed paragraph field formatting to not include extra spacing.",
      },
    ],
  },
  {
    version: "0.13",
    date: "September 29, 2023",
    summary:
      "Additional features, updates to existing features, and bug fixes as described below:",
    notes: [
      {
        type: "Feature",
        title: "1. Positive Response Predefined Comments (DEV-1149)",
        description:
          "Added support for predefined comments in the Positive Response field.",
      },
      {
        type: "Feature",
        title: "2. User Data (DEV-477)",
        description:
          "Admins can now create their organization's own 'User Data' in the 'Default' advanced settings dialog when setting up fields in a form.",
      },
      {
        type: "Bug",
        title: "3. User Notifications (DEV-1130)",
        description:
          "Inactive or notification-disabled users will no longer receive 811 notifications via text or email, including standard notifications and workflows.",
      },
      {
        type: "Bug",
        title: "4. Paragraph Field Fixes (DEV-1081)",
        description:
          "Fixed an issue where paragraph fields did not print as expected to the generated PDF.",
      },
    ],
  },
  {
    version: "0.12",
    date: "September 15, 2023",
    summary:
      "Additional features, updates to existing features, and bug fixes as described below:",
    notes: [
      {
        type: "Feature",
        title: "1. ArcGIS Sign In After Integrating (DEV-1091)",
        description:
          "Automatically signs the user in with ArcGIS once they add a GIS integration.",
      },
      {
        type: "Feature",
        title: "2. Updated to Support Cityworks 23 (DEV-957 and DEV-1115)",
        description:
          "This represents a major update to the Cityworks integration. The entire Cityworks integration was revamped to support the new Cityworks 23 security requirements, while still supporting the older version of Cityworks as well.",
      },
      {
        type: "Feature",
        title:
          "3. Better Error-Handling for Test Connection for GIS Layers (DEV-998)",
        description:
          "Updated the 'Test Connection' button on GIS Layers to show more descriptive error messages when the connection fails.",
      },
      {
        type: "Bug",
        title: "4. Form Questions Not Selectable (DEV-1118)",
        description:
          "Fixed an issue where some form questions where difficult to select on the form builder.",
      },
      {
        type: "Bug",
        title: "5. Update Question JSON (DEV-1116)",
        description:
          "Fixed an issue where the question JSON was not being updated. Now, the user can edit the question JSON and save the changes.",
      },
      {
        type: "Bug",
        title: "6. Form Placeholder Loses Formatting (DEV-1081)",
        description:
          "Fixed an issue where the form chip on the doc builder was losing the formatting that was applied to it. Now, the placeholder retains the formatting that is applied to it.",
      },
      {
        type: "Bug",
        title: "7. Email Format Bug (DEV-1124)",
        description:
          "Fixed a bug where the email format would get saved incorrectly when an email was updated. Now, the email format is saved correctly when an email is updated.",
      },
    ],
  },
  {
    version: "0.11",
    date: "September 6, 2023",
    summary: "Additional features and a bug fix as described below:",
    notes: [
      {
        type: "Feature",
        title: "1. Layer Importing (DEV-1093)",
        description:
          "If an admin is signed in with ArcGIS Online or Enterprise, feature and map services can now be imported while using the Create Layer UtiliBot.",
      },
      {
        type: "Feature",
        title: "2. Virginia Positive Response Field Update (DEV-992)",
        description:
          "It is now possible to respond to multiple show codes on a single form.",
      },
      {
        type: "Feature",
        title: "3. MISS DIG (Email) Positive Response Update (DEV-1054)",
        description:
          "811 Codes with MISS DIG (Email) selected can now add additional account codes to submit additional positive responses on the same 811 Code.",
      },
      {
        type: "Bug",
        title: "4. Formatting Parameters in Doc Builder (DEV-955)",
        description:
          "Fixed an issue where you could not apply different text format options to the parameters in Doc Builder.",
      },
      {
        type: "Bug",
        title: "5. Connect Button for ArcGIS Integration (DEV-1120)",
        description:
          "Fixed an issue where the 'Connect' button was disabled permanently after being clicked once.",
      },
    ],
  },
  {
    version: "0.10",
    date: "August 17, 2023",
    summary: "Bug fix as described below:",
    notes: [
      {
        type: "Bug",
        title: "1. Paragraph Field Formatting (DEV-955)",
        description:
          "Fixed an issue where in the Doc Builder the paragraph field was not added correctly.",
      },
    ],
  },
  {
    version: "0.9",
    date: "August 11, 2023",
    summary: "Additional features and a bug fix as described below:",
    notes: [
      {
        type: "Feature",
        title: "1. MISS DIG (Email) Positive Response Updates (DEV-1014)",
        description:
          "Added support for sending in a positive response for each station code included on an 811 Code for MISS DIG (Email). Admins can configure this by editing the 811 Code and adding additional account codes.",
      },
      {
        type: "Feature",
        title: "2. Login Updates (DEV-297)",
        description:
          "Added a dialog that provides a more detailed error when a user tries to login to an inactive user or to an inactive organization.",
      },
      {
        type: "Bug",
        title: "3. Notifications Tab Confusion (DEV-1072)",
        description:
          "Removed the 'Notifications' tab from the navigation menu.",
      },
    ],
  },
  {
    version: "0.8",
    date: "July 18, 2023",
    summary: "Additional feature as described below:",
    notes: [
      {
        type: "Feature",
        title: "1. Workflows (DEV-945)",
        description:
          "Added the ability to create 'Workflows' where admins can configure customer workflows to be triggered when a new ticket is received. Example workflows include custom notifications and assigning tickets.",
      },
    ],
  },
  {
    version: "0.7",
    date: "July 11, 2023",
    summary: "Additional features and a bug fix as described below:",
    notes: [
      {
        type: "Feature",
        title: "1. Archive Forms (DEV-990)",
        description:
          "Added the ability to be able to archive forms that are no longer used as well as the ability to make those archived forms active again.",
      },
      {
        type: "Feature",
        title: "2. Added Positive Response for Diggers Hotline (DEV-921)",
        description: "Added Positive Response codes for One Call of Wyoming.",
      },
      {
        type: "Bug",
        title: "3. Visible Condition Bug (DEV-1023)",
        description:
          "Fixed an issue where the 'Visible' setting was not saving as expected when editing Positive Response choices. Now, 'Never' and 'Conditional' visibilities can be saved as visibility conditions.",
      },
    ],
  },
  {
    version: "0.6",
    date: "July 7, 2023",
    summary: "Updates to existing features and a bug fix as described below:",
    notes: [
      {
        type: "Feature",
        title: "1. Positive Response Chip Update (DEV-1017)",
        description:
          "Updated the inserted templating in the Docs Builder for Positive Response fields.",
      },
      {
        type: "Feature",
        title: "2.  Add Positive Response for Arkansas811 (DEV-623)",
        description: "Updated the Positive Response codes for Arkansas811.",
      },
      {
        type: "Bug",
        title: "3.  Add Positive Response for Arkansas811 (DEV-1013)",
        description:
          "Fixed an issue where users were being asked to contact customer support to configure the passive response code in the UtiliSync811 app due to issues with the new Positive Response field. Now, users can submit the form without encountering any errors.",
      },
    ],
  },
  {
    version: "0.5",
    date: "July 1, 2023",
    summary: "Additional features as described below:",
    notes: [
      {
        type: "Feature",
        title: "1. Create Screen Recording Widget (DEV-925)",
        description:
          "Added a screen recording widget for users to capture and upload screen recordings. Additionally, videos and images and be uploaded directly from the app.",
      },
      {
        type: "Feature",
        title: "2. Positive Response Field Type Update (DEV-974)",
        description:
          "Added a new Positive Response field type that allows for more structure based off of the one-call center.",
      },
    ],
  },
  {
    version: "0.4",
    date: "June 8, 2023",
    summary: "Additional features and a bug fix as described below:",
    notes: [
      {
        type: "Feature",
        title: "1. Updated Cityworks Sign-In (DEV-949)",
        description:
          "Moved the Cityworks sign-in feature to be under the user in the navigation menu, similar to the Docs app.",
      },
      {
        type: "Feature",
        title: "2. Verify Cityworks Token (DEV-960)",
        description:
          "Now, the user's Cityworks token is verified before showing data from Cityworks on the UtiliBot tab. If the token is not valid, the user is presented with a Cityworks sign-in dialog.",
      },
      {
        type: "Bug",
        title: "3. UtiliSync Field Updates (DEV-26)",
        description:
          "Fixed an issue where UtiliSync fields did not delete as expected. Also, UtiliSync fields customization table is now better formatted.",
      },
    ],
  },
  {
    version: "0.3",
    date: "June 6, 2023",
    summary: "Additional features and a bug fix as described below:",
    notes: [
      {
        type: "Feature",
        title: "1. UtiliSync Field Updates (DEV-26)",
        description:
          "UtiliSync fields can now be ordered, hidden, and grouped in sections.",
      },
      {
        type: "Feature",
        title: "2. GIS Field Updates (DEV-898)",
        description:
          "GIS fields can now be ordered, hidden, grouped in sections, and given a 'UtiliSync Label' which overrides the GIS field name and alias.",
      },
      {
        type: "Bug",
        title: "3. Cityworks Token Issues (DEV-949)",
        description:
          "Fixed an issue where when the Cityworks token was invalid, the user was not notified. Now the user is prompted to sign out and back in to get a fresh Cityworks token.",
      },
    ],
  },
  {
    version: "0.2",
    date: "May 20, 2023",
    summary:
      "Additional feature, updates to an existing feature, and bug fixes as described below:",
    notes: [
      {
        type: "Feature",
        title: "1. Adding HEX Support to Color Pickers (DEV-913)",
        description:
          "Added a HEX code supported option to the Doc Builder color clickers for enhanced color selection.",
      },
      {
        type: "Feature",
        title: "2. Hide Cityworks Tab (DEV-663)",
        description:
          "The Cityworks UtiliBot tab automatically hides when building forms if there is no Cityworks integration.",
      },
      {
        type: "Bug",
        title: "3. 'Reports to' is Not Saving (DEV-917)",
        description:
          "Fixed an issue where 'Reports To' field in user data was not being saved. Now, the system reliably saves user's data.",
      },
      {
        type: "Bug",
        title: "4. Error Creating User (DEV-863)",
        description:
          "Fixed an issue where account creation was halted with a loading spinner when phone numbers exceeded 10 digits or contained special characters. Now, only 10-digit phone numbers without spaces or dashes are allowed in the add and edit user form.",
      },

      {
        type: "Bug",
        title: "5. Edit Form Menu (DEV-928)",
        description:
          "Fixed an issue where the form builder navigation menu would not open and close as expected. Now, the menu remains open when hovered, providing a consistent and seamless user experience..",
      },
    ],
  },
];
