export const LOGIC = {
  IF: "if",
  ELSE_IF: "else-if",
  ELSE: "else",
  END_IF: "end-if",
};

export const LOGIC_COMPARISON = {
  EQUALS: "equals",
  DOES_NOT_EQUAL: "does-not-equal",
  CONTAINS: "contains",
  NOT_EMPTY: "not-empty",
  GREATER_THAN: "greater-than",
  GREATER_THAN_OR_EQUAL: "greater-than-or-equal",
  LESS_THAN: "less-than",
  LESS_THAN_OR_EQUAL: "less-than-or-equal",
};

export const OPERATOR = {
  AND: "and",
  OR: "or",
};
