var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        value: _vm.showErrorDialog,
        "max-width": "500px",
        persistent: "",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-text", { staticClass: "py-3" }, [
            _vm._v(
              " An error just occurred. Error: 500. Please try reloading the app. If this continues, please reach out to support and let them know the date and time the error occurred, so they can look it up in the logs. "
            ),
          ]),
          _c(
            "v-card-actions",
            { staticClass: "d-flex justify-end px-5 py-3" },
            [
              _c(
                "v-btn",
                {
                  attrs: { text: "", color: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.setErrorOccurred(false)
                    },
                  },
                },
                [_vm._v(" Close ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }