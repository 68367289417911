import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

Vue.use(VueRouter);

const beforeEnter = (to, from, next) => {
  try {
    const auth = JSON.parse(localStorage.getItem("auth"));
    const refreshToken = auth.refresh_token;
    if (to.name !== "Login" && !refreshToken) {
      return next({ name: "Login", query: { redirectUrl: to.fullPath } });
    }
    return next();
  } catch (error) {
    return next({ name: "Login", query: { redirectUrl: to.fullPath } });
  }
};

const beforeEnterNoAuth = (to, from, next) => {
  store.commit("setMenuState", false);
  return next();
};

const routes = [
  {
    path: "/",
    redirect: "/organization",
    beforeEnter,
  },
  {
    name: "Login",
    path: "/login",
    component: () => import("@/views/Login"),
  },
  {
    name: "CreatePassword",
    path: "/createPassword",
    component: () => import("@/views/Login"),
    beforeEnter: beforeEnterNoAuth,
  },
  {
    name: "Forms",
    path: "/forms",
    component: () => import("@/views/Forms"),
    beforeEnter,
  },
  {
    name: "Reports",
    path: "/reports",
    component: () => import("@/views/Reports"),
    beforeEnter,
  },
  {
    name: "EsriRedirect",
    path: "/esriRedirect",
    component: () => import("@/views/EsriRedirect"),
  },
  {
    name: "Maps",
    path: "/maps",
    component: () => import("@/views/Maps"),
    beforeEnter,
  },
  {
    name: "811 Codes",
    path: "/811Codes",
    component: () => import("@/views/MemberCodes811"),
    beforeEnter,
  },
  {
    name: "Layers",
    path: "/layers",
    component: () => import("@/views/Layers"),
    beforeEnter,
  },
  {
    name: "VersionLog",
    path: "/versionLog",
    component: () => import("@/views/VersionLog"),
    beforeEnter,
  },
  {
    name: "Organization",
    path: "/organization",
    component: () => import("@/views/Organization"),
    beforeEnter,
  },
  {
    name: "ContactBook",
    path: "/contactBook",
    component: () => import("@/views/ContactBook"),
    beforeEnter,
  },
  {
    name: "ConnectArcGISRedirect",
    path: "/connectArcGISRedirect",
    component: () => import("@/views/ConnectArcGISRedirect"),
  },
  {
    name: "Basemaps",
    path: "/basemaps",
    component: () => import("@/views/Basemaps"),
    beforeEnter,
  },
  {
    name: "Captures",
    path: "/captures",
    component: () => import("@/views/Captures"),
    beforeEnter: beforeEnter,
  },
  {
    name: "UpdateObjectIds",
    path: "/updateObjectIds",
    component: () => import("@/views/UpdateObjectIds"),
    beforeEnter: beforeEnter,
  },
  {
    name: "UpdateGlobalIds",
    path: "/updateGlobalIds",
    component: () => import("@/views/UpdateGlobalIds"),
    beforeEnter: beforeEnter,
  },
  {
    name: "FeatureService",
    path: "/featureService",
    component: () => import("@/views/FeatureService"),
    beforeEnter: beforeEnter,
  },
  {
    name: "Capture",
    path: "/capture/:fileId",
    component: () => import("@/views/Capture"),
    beforeEnter: beforeEnterNoAuth,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
