var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.hideToolbar
    ? _c(
        "div",
        { staticClass: "d-flex justify-center top-center-element" },
        [
          _c(
            "v-card",
            {
              attrs: {
                color:
                  _vm.isRecording && !_vm.recordingPaused
                    ? "#ff7777"
                    : "#f2f2f2",
              },
            },
            [
              _c(
                "v-card-text",
                { staticClass: "pa-1" },
                [
                  _c("v-card", { attrs: { elevation: "0" } }, [
                    _c("div", { staticClass: "d-flex" }, [
                      !_vm.isRecording & !_vm.startedRecording
                        ? _c(
                            "div",
                            { staticClass: "d-flex" },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { icon: "" },
                                  on: { click: _vm.startRecording },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { color: "#ff6060" } },
                                    [_vm._v(_vm._s(_vm.mdiRecord))]
                                  ),
                                ],
                                1
                              ),
                              _c("div", {
                                staticStyle: { border: "1px #eeeeee solid" },
                              }),
                              _c(
                                "v-btn",
                                {
                                  attrs: { icon: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.$emit("close-capture-toolbar")
                                    },
                                  },
                                },
                                [_c("v-icon", [_vm._v(_vm._s(_vm.mdiClose))])],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.isRecording
                        ? _c(
                            "div",
                            { staticClass: "d-flex" },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { icon: "" },
                                  on: { click: _vm.pauseResumeRecording },
                                },
                                [
                                  _c("v-icon", [
                                    _vm._v(_vm._s(_vm.pauseRecordIcon)),
                                  ]),
                                ],
                                1
                              ),
                              _c("div", {
                                staticStyle: { border: "1px #eeeeee solid" },
                              }),
                              _c(
                                "v-btn",
                                {
                                  attrs: { icon: "" },
                                  on: { click: _vm.stopRecording },
                                },
                                [
                                  _c("v-icon", [
                                    _vm._v(_vm._s(_vm.mdiStopCircle)),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.isRecording
                        ? _c("div", {
                            staticStyle: { border: "1px #eeeeee solid" },
                          })
                        : _vm._e(),
                      _vm.startedRecording || _vm.isRecording
                        ? _c(
                            "div",
                            {
                              staticClass: "d-flex justify-center",
                              staticStyle: { height: "36px", width: "36px" },
                            },
                            [
                              _c("div", { staticClass: "align-self-center" }, [
                                _vm._v(_vm._s(_vm.countdownElement)),
                              ]),
                            ]
                          )
                        : _vm._e(),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }