var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        value: _vm.showBadRequestErrorDialog,
        "max-width": "500px",
        persistent: "",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-text", { staticClass: "py-3" }, [
            _vm._v(" An error occurred: "),
            _c(
              "ul",
              { staticClass: "mx-0" },
              _vm._l(_vm.badRequestError, function (e) {
                return _c("li", { key: e }, [_vm._v(_vm._s(e))])
              }),
              0
            ),
          ]),
          _c(
            "v-card-actions",
            { staticClass: "d-flex justify-end px-5 py-3" },
            [
              _c(
                "v-btn",
                {
                  attrs: { text: "", color: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.setBadRequestError(undefined)
                    },
                  },
                },
                [_vm._v(" Close ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }