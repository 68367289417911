import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "./plugins/vee-validate";
import "./plugins/axios";
import "./plugins/vue-signature-pad";
import "./plugins/filters";
import "./plugins/v-mask";

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

export const EventBus = new Vue();
