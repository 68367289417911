import Vue from "vue";
import moment from "moment";
import { LOGIC } from "@/constants/logicChoices";

const items = {
  SECTION: "Section",
  NUMBER: "Number",
  SINGLE_SELECT: "Single Select",
  MULTI_SELECT: "Multi Select",
  EMAIL: "Email",
  DATE: "Date",
  TIME: "Time",
  HEADER: "Header",
  CALCULATION: "Calculation",
  INFORMATION_TEXT: "Text Info",
  INFORMATION_URL: "URL",
  INFORMATION_HEADER: "Header Info",
  INFORMATION_IMAGE: "Info Image",
  SIGNATURE: "Signature",
  ACTION_ITEM: "Action Item",
  GROUP: "Table",
  POSITIVE_RESPONSE: "Positive Response",
};

const layerTypeObj = {
  U: "UtiliSync Layer",
  L: "811 Tickets Layer",
  F: "ArcGIS Feature Service",
  S: "ArcGIS Map Service",
  W: "WMS Layer",
};

const formTemplateLogic = {
  [LOGIC.IF]: "If",
  [LOGIC.ELSE_IF]: "Else-If",
  [LOGIC.ELSE]: "Else",
  [LOGIC.END_IF]: "End-If",
};

Vue.filter("itemTitle", (value, selectedItem) => {
  if (value) {
    if (selectedItem?.type === "INFORMATION") {
      return items[`INFORMATION_${value}`];
    }
    if (value === "FILE") {
      return selectedItem?.question?.isImage ? "Photo" : "File";
    }

    if (value === "TEXT") {
      return selectedItem?.question?.isLong ? "Paragraph" : "Singleline Text";
    }
    return items[value];
  }
  return items[value];
});

Vue.filter("readableQuestion", (value, formDefinition) => {
  if (value) {
    const { sections } = formDefinition.form;
    const nestedItems = sections.map(({ items }) => items);
    const items = nestedItems.flat().filter(({ question }) => {
      return ["NUMBER", "SINGLE_SELECT"].includes(question?.type);
    });

    let readableQuestion = value;
    const params = [...value.matchAll(/{{(.*?)}}/g)];
    const formFieldItems = nestedItems.flat().filter(({ question }) => {
      return ["GROUP"].includes(question?.type);
    });
    const externalParams = [...value.matchAll(/{{(params.\d+.col.\d+)}}/g)];
    for (const p of externalParams) {
      const [fullParam, param] = p;
      const [formFieldParam] = param.match(/params.\d+/);
      const [groupColumnParam] = param.match(/col.\d+/);
      const [, questionId] = formFieldParam.split(".");
      const [, columnNumber] = groupColumnParam.split(".");
      const formFieldItem = formFieldItems.find((i) => +i.id === +questionId);
      const item = formFieldItem.question.groupedItems[columnNumber - 1];
      readableQuestion = readableQuestion.replace(
        fullParam,
        `<${item?.question?.label}>`
      );
    }

    for (const p of params) {
      const [fullParam, param] = p.toString().split(",");
      const [, questionId] = param.split(".");
      const item = items.find((i) => i.id === +questionId);
      readableQuestion = readableQuestion.replace(
        fullParam,
        `<${item?.question?.label}>`
      );
    }
    return readableQuestion;
  }
});

Vue.filter("groupReadableQuestion", (value, formDefinition, groupItems) => {
  if (value && Array.isArray(groupItems)) {
    const items = groupItems.filter(({ question }) => {
      return ["NUMBER", "SINGLE_SELECT"].includes(question?.type);
    });

    let readableQuestion = value;
    const params = [...value.matchAll(/{{(.*?)}}/g)];
    const { sections } = { ...formDefinition.form };
    const nestedItems = sections.map(({ items }) => items);
    const formFieldItems = nestedItems.flat();
    const externalParams = [...value.matchAll(/{{(params.\d+.col.\d+)}}/g)];
    for (const p of externalParams) {
      const [fullParam, param] = p;
      const [formFieldParam] = param.match(/params.\d+/);
      const [groupColumnParam] = param.match(/col.\d+/);
      const [, questionId] = formFieldParam.split(".");
      const [, columnNumber] = groupColumnParam.split(".");
      const formFieldItem = formFieldItems.find((i) => +i.id === +questionId);
      const item = formFieldItem.question.groupedItems[columnNumber - 1];
      readableQuestion = readableQuestion.replace(
        fullParam,
        `<${item?.question?.label}>`
      );
    }

    for (const p of params) {
      const [fullParam, param] = p.toString().split(",");
      const [, questionId] = param.split(".");
      const item = items.find((i) => i.id === +questionId);
      readableQuestion = readableQuestion.replace(
        fullParam,
        `<${item?.question?.label}>`
      );
    }
    return readableQuestion;
  }
});

Vue.filter("layerType", (value) => {
  return layerTypeObj[value];
});

Vue.filter("formatVariableDate", (value, format) => {
  if (value) {
    return moment(new Date(value)).format(format);
  }
});

Vue.filter("formatDate", (value) => {
  if (value) {
    return moment(new Date(value)).format("MM/DD/YYYY");
  }
});

Vue.filter("formatDateUTC", (value) => {
  if (value) {
    return moment.utc(new Date(value)).format("MM/DD/YYYY");
  }
});

Vue.filter("capitalize", (value) => {
  return value
    ?.replaceAll("_", " ")
    ?.replace(/\b[a-z]/g, (match) => match.toUpperCase());
});

Vue.filter("formTemplateLogicType", (value) => {
  return formTemplateLogic[value];
});
